<template>
  <div>
    <Header />

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-show="showModalForm"
        @click="showModalForm = false"
        class="bg-cover"
      ></div>
    </transition>

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-show="showModalForm" class="modal">
        <div v-show="form.subject == 'newsletter'" class="subtitle text-center">
          CADASTRE-SE
        </div>
        <div
          v-show="form.subject != 'newsletter'"
          class="subtitle text-center"
          style="text-transform: uppercase"
        >
          SAIBA MAIS SOBRE O {{ form.subject }}
        </div>
        <p v-show="form.subject == 'newsletter'" class="text-center mt-1">
          Receba nossas notícias e informações
        </p>
        <p v-show="form.subject != 'newsletter'" class="text-center mt-1">
          Cadastre-se abaixo e logo entraremos em contato
        </p>
        <div class="input-item">
          <span>Nome</span>
          <input v-model="form.name" type="text" />
        </div>
        <div class="input-item">
          <span>E-mail</span>
          <input v-model="form.email" type="email" />
        </div>
        <div class="input-item">
          <span>Data de nascimento</span>
          <input v-model="form.birthdate" type="date" />
        </div>

        <vue-recaptcha
          class="mt-1"
          ref="recaptcha"
          @verify="onVerify"
          @expired="onExpired"
          sitekey="6LcY6j8aAAAAAH3V89ex5-QQka24KD9ihjnZswW2"
          :loadRecaptchaScript="true"
        />

        <p v-show="warningMessage != ''" class="warning red">
          {{ warningMessage }}
        </p>

        <transition enter-active-class="animated fadeInLeft">
          <p
            v-show="formSent"
            class="text-center mt-2 text-desc"
            style="color: var(--primary)"
          >
            Obrigado pelo interesse!
          </p>
        </transition>

        <div
          @click="sendForm"
          class="btn border icon send-form"
          v-show="validHuman"
        >
          <span v-show="!sendingForm">ENVIAR</span>
          <font-awesome-icon
            v-show="!sendingForm"
            :icon="['fas', 'chevron-right']"
          />
          <div v-show="sendingForm" class="loading2 yellow"></div>
        </div>
      </div>
    </transition>

    <section class="intro">
      <div id="homeSlider" class="swiper-container overflow-initial">
        <div class="swiper-wrapper">
          <div v-for="banner in banners" :key="banner.id" class="swiper-slide">
            <img class="bg-img" :src="banner.image" alt="imagem de fundo" />
            <div class="container">
              <h1 class="banner-title" v-html="banner.label"></h1>
              <button
                @click="bannerClick(banner.link)"
                class="banner-btn"
                :style="`color:${banner.buttonColor};`"
                :ref="`banner-btn-${banner.id}`"
                @mouseover="buttonHover(banner, 'add')"
                @mouseleave="buttonHover(banner, 'remove')"
              >
                {{ banner.buttonText }}
              </button>
            </div>
          </div>
        </div>
        <div
          v-show="banners.length > 1"
          class="swiper-pagination banner-home-pagination"
        ></div>

        <!-- <div id="homeSliderNext" class="banner-next">
          <font-awesome-icon icon="chevron-right" />
        </div>
        <div id="homeSliderPrev" class="banner-prev">
          <font-awesome-icon icon="chevron-left" />
        </div> -->
      </div>
    </section>
    <section class="about">
      <div class="container">
        <div class="block desktop5 pr-2-desktop tablet6 mobile12">
          <h1 class="before-title">TRADECORP</h1>
          <div class="title">Visão GLOBAL, com pés firmes no LOCAL</div>
        </div>
        <div class="block desktop7 tablet6 mobile12 mt-2-mobile">
          <p class="text-desc">
            A Tradecorp é o resultado da fusão de duas empresas, após aquisição
            da brasileira Microquimica pela espanhola Tradecorp, em Janeiro de
            2019. A união e sinergia das empresas apresenta ao mercado agrícola
            um amplo portfólio de fertilizantes,
            biofertilizantes/bioestimulantes, inoculantes, adjuvantes e
            reguladores de crescimento vegetal.
          </p>
          <p class="text-desc mt-1">
            A tecnologia de seus produtos atende as demandas atuais do mercado,
            de uma produção segura, produtiva e sustentável.
          </p>

          <div class="flex-between mt-1">
            <div class="card mobile12">
              <img src="../assets/images/shield.png" alt="escudo" />
              <div>
                <div class="subtitle">COMPROMETIMENTO</div>
                <p>Nosso sucesso é o seu sucesso</p>
              </div>
            </div>
            <div class="card mobile12 mt-2-mobile mt-2-tablet">
              <img src="../assets/images/wheat.png" alt="trigo" />
              <div>
                <div class="subtitle">SUSTENTÁVEL E EFICAZ</div>
                <p>Inovação com resultado para o produtor</p>
              </div>
            </div>
          </div>

          <router-link
            to="/empresa"
            class="btn border icon desktop4 tablet-6 mt-3-desktop"
          >
            <span>LEIA MAIS</span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" />
          </router-link>
        </div>
      </div>
    </section>

    <section class="products">
      <div class="container">
        <div class="block-img desktop5 pr-2-desktop tablet6 mobile12"></div>
        <div class="block desktop7 tablet6 mobile12 mt-2-mobile">
          <div class="before-title">O QUE OFERECEMOS</div>
          <div class="title text-white">NOSSOS PRODUTOS</div>
          <p class="text-desc text-white mt-1">
            Conheça nossa linha de produtos e solicite seu orçamento
            personalizado pelo nosso site.
          </p>
          <ul class="prods">
            <li v-for="cat in categories" :key="cat.id">
              <router-link :to="`/produtos?categoryId=${cat.id}`">{{
                cat.name
              }}</router-link>
            </li>
          </ul>

          <router-link to="/produtos" class="btn border icon yellow desktop4">
            <span>VER TODOS</span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" />
          </router-link>
        </div>
      </div>
    </section>

    <section class="blog">
      <div class="container">
        <div class="before-title text-center">BLOG</div>
        <div class="title text-center">ARTIGOS EM DESTAQUE</div>
        <p class="text-desc text-center mt-1">
          Acompanhe nossos conteúdos técnicos sobre nutrição vegetal,
          bioestimulação e agronomia, além dos últimos eventos da empresa.
        </p>

        <div class="posts-holder mt-2">
          <PostCard
            v-for="post in posts"
            :key="post.id"
            :title="post.title"
            :text="post.summary"
            :image="post.image"
            :slug="post.slug"
            :date="post.startDate"
          />
        </div>
      </div>
    </section>

    <section class="results">
      <div class="container">
        <div class="before-title text-center">RESULTADOS</div>
        <div class="title text-center text-white">NOSSOS NÚMEROS</div>
        <div class="result-cards">
          <div class="result-card">
            <div class="number text-center">44+35</div>
            <p class="text-desc text-white text-center">Anos de experiência</p>
          </div>
          <div class="result-card mt-2-mobile">
            <div class="number text-center">70</div>
            <p class="text-desc text-white text-center">
              Agrônomos e Técnicos Agrícolas
            </p>
          </div>
          <div class="result-card mt-2-mobile mt-2-tablet">
            <div class="number text-center">+300</div>
            <p class="text-desc text-white text-center">
              Pesquisas realizadas no Brasil
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="team">
      <div class="container">
        <div class="block flex">
          <div class="line"></div>
          <h1 class="before-title">AMPLA REDE DE ATENDIMENTO</h1>
          <div class="title">ENCONTRE NOSSO TIME</div>

          <p class="text-desc mt-2 mr-3-desktop mr-2-tablet">
            A Tradecorp conta com uma equipe altamente qualificada e
            comprometida com o resultado do produtor rural brasileiro. Nossos
            responsáveis Técnico/Comerciais estão distribuídos por todo o
            Brasil, nas mais importantes regiões agrícolas do país, prontos para
            atender à agricultores, distribuidores e agroindústrias.
          </p>
          <router-link to="/nosso-time" class="btn border icon mt-2">
            <span>PESQUISE</span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" />
          </router-link>
        </div>
        <img
          class="mobile-hidden team-img"
          src="../assets/images/imagemparceria.jpg"
          alt="rede de atendimento"
        />
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import http from "@/http";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
Swiper.use([Navigation, Pagination, Autoplay]);
import "swiper/swiper-bundle.min.css";
import PostCard from "@/components/PostCard";
import validators from "@/util/validators";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    PostCard,
    "vue-recaptcha": VueRecaptcha,
  },
  data() {
    return {
      banners: [],
      posts: [],
      categories: [],
      showModalForm: false,
      form: {
        subject: "newsletter",
        name: "",
        email: "",
        birthdate: "",
      },
      warningMessage: "",
      sendingForm: false,
      formSent: false,
      validHuman: false,
    };
  },
  created() {
    this.loadBanners();
    this.loadCategories();
  },
  methods: {
    onVerify: function (response) {
      console.log("Verify: " + response);
      this.validHuman = true;
    },
    onExpired: function () {
      console.log("Expired");
      this.validHuman = false;
    },
    sendForm() {
      this.formSent = false;

      if (this.form.name.length < 3) {
        this.warningMessage = "Por favor, informe o seu nome";
        return;
      }
      if (!validators.validateEmail(this.form.email)) {
        this.warningMessage = "Por favor, informe um e-mail válido";
        return;
      }
      if (this.form.birthdate.length < 8) {
        this.warningMessage = "Por favor, informe o seu nascimento";
        return;
      }

      this.warningMessage = "";
      this.postForm();
    },
    postForm() {
      this.sendingForm = true;
      http
        .post("Contact", this.form)
        .then(() => {
          this.formSent = true;
          this.form = {
            subject: "newsletter",
            name: "",
            email: "",
            birthdate: "",
          };
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.sendingForm = false;
        });
    },
    bannerClick(val) {
      if (val && val.toLowerCase().indexOf("open_modal=") != -1) {
        this.form.subject =
          val.split("open_modal=")[1] &&
          val.split("open_modal=")[1].toLowerCase() != "newsletter"
            ? val.split("open_modal=")[1]
            : this.form.subject;

        this.showModalForm = true;
      } else {
        if (val.indexOf(location.origin) != -1) {
          window.location = val;
        } else {
          window.open(val, "_blank");
        }
      }
    },
    loadBanners() {
      http
        .get(`banner/List?orderBy=timestamp&take=-1&pageName=Home`)
        .then((res) => {
          this.banners = res.data.list;
          this.getPosts();
          this.initSlider();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadCategories() {
      http
        .get(`category/List?orderBy=name-`)
        .then((res) => {
          this.categories = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPosts() {
      http
        .get(`post/List?orderBy=startDate&take=3&showOnHome=true`)
        .then((res) => {
          this.posts = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    buttonHover(banner, action) {
      if (action == "add") {
        this.$refs[`banner-btn-${banner.id}`][0].style.backgroundColor =
          banner.buttonColor;
        this.$refs[`banner-btn-${banner.id}`][0].style.color = "#fff";
      } else {
        this.$refs[`banner-btn-${banner.id}`][0].style.color =
          banner.buttonColor;
        this.$refs[`banner-btn-${banner.id}`][0].style.backgroundColor = "#fff";
      }
    },
    initSlider() {
      setTimeout(() => {
        this.slider = new Swiper("#homeSlider", {
          autoplay: this.banners.length > 1 ? { delay: 6000 } : false,
          speed: 450,
          slidesPerView: 1,
          spaceBetween: 0,
          loop: false,
          pagination: {
            el: ".swiper-pagination",
          },
          // navigation: {
          //   nextEl: "#homeSliderNext",
          //   prevEl: "#homeSliderPrev",
          // },
        });
      }, 250);
    },
  },
};
</script>
<style lang="scss" scoped>
section.intro {
  padding: 0 !important;
}

.pop-up {
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  max-width: 520px;
  max-height: 100vh;
  margin: 0 auto;
  z-index: 999;

  .close-pop-up {
    position: relative;
    margin-left: auto;
    margin-bottom: 1em;
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc;
    border-radius: 100%;
    svg {
      font-size: 1.2em;
    }
  }

  img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.subtitle {
  position: relative;
  font-size: 1.6em;
  font-family: fontBold;
}

.swiper-slide {
  position: relative;
  height: calc(100vh - 120px);
  overflow: hidden;
  .bg-img {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    max-height: 140%;
    top: 50%;
    left: 50%;
    margin: 0 auto;
    right: 0;
    transform: translate3d(-50%, -50%, 0);
  }
}

.swiper-slide .container {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
}

.banner-title {
  position: relative;
  max-width: 480px;
  font-size: 3.2em;
  color: #fff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  margin-top: -90px;
}

.banner-btn {
  font-family: fontMedium;
  position: relative;
  display: inline-block;
  margin-top: 25px;
  padding: 15px 28px;
  background-color: #fff;
  transition: background-color 0.25s ease, color 0.25s ease;
  cursor: pointer;
}

.banner-bottom {
  position: absolute;
  max-width: 58%;
  bottom: 0;
  left: 0;
  margin-right: auto;
}

.banner-next,
.banner-prev {
  position: absolute;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ecedf2;
  border-radius: 100%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  top: 50%;
  margin: 0 auto;
  left: 2.5rem;
  right: 2.5rem;
  transform: translateY(-50%);
  z-index: 2;
  opacity: 0.75;
  cursor: pointer;
  transition: background-color 0.2s ease, opacity 0.2s ease;

  &:hover {
    background-color: #fff;
    opacity: 1;
  }

  &.swiper-button-disabled {
    opacity: 0.5;
  }

  svg {
    font-size: 1.6em;
    color: #333;
  }
}

.banner-next {
  left: initial;
}
.banner-prev {
  right: initial;
}

section.about .container {
  display: flex;
  justify-content: space-between;
  gap: 0 25px;
}

.card {
  position: relative;
  width: 48%;
  min-width: 270px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  img {
    max-height: 70px;
  }
  div {
    margin-left: 15px;
    .subtitle {
      font-weight: bold;
      font-size: 1.2em;
      margin: 0 0 6px 0;
    }
    p {
      font-size: 1.1em;
    }
  }
}

section.products {
  background-color: var(--bg-green);
  .container {
    display: flex;
    justify-content: space-between;
    gap: 0 25px;
  }

  ul.prods {
    position: relative;
    border-left: 1px solid var(--yellow1);
    margin-top: 25px;
    padding: 0 0 4px 18px;
    li a {
      position: relative;
      display: block;
      font-family: fontBold;
      font-size: 1.5em;
      line-height: 1.5em;
      margin-top: 12px;
      color: #fff;
      letter-spacing: 1px;
      &:hover {
        color: var(--yellow1);
      }
    }
  }
}

.block-img {
  position: relative;
  background: url("../assets/images/MicroquimicaTradecorp01.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
  margin: -125px 0;
}

section.results {
  background-image: url("../assets/images/institucional2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: var(--bg-green);
    top: 0;
    left: 0;
  }
}

.result-cards {
  position: relative;
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 65px;
  .result-card .number {
    font-family: fontBold;
    font-size: 7em;
    color: var(--yellow1);
    margin-bottom: 12px;
  }
}

section.team .container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 0;
  justify-content: center;
  align-items: center;

  .block.flex {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .line {
    position: relative;
    width: 100%;
    height: 2px;
    background-color: var(--yellow1);
    margin-bottom: 35px;
  }

  .team-img {
    max-width: 100%;
  }
}

.modal {
  position: fixed;
  width: 90%;
  max-width: 480px;
  background-color: #fff;
  padding: 20px;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  z-index: 9999;
}

@media screen and (max-width: 720px) {
  section.about .container,
  section.products .container {
    flex-wrap: wrap;
  }
  .block-img {
    height: 240px;
    margin-bottom: 0;
  }
}
</style>
