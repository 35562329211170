<template>
  <article>
    <router-link :to="`/blog/post/${slug}`">
      <div class="img-holder" :style="`background-image:url(${image})`"></div>
    </router-link>
    <router-link :to="`/blog/post/${slug}`">
      <div class="title-holder">
        <div class="date-block">
          <p>{{ date | moment("DD") }}</p>
          <p>{{ date | moment("MMM") }}</p>
        </div>
        <div class="text">
          <h1>{{ title }}</h1>
        </div>
      </div>
    </router-link>
    <router-link :to="`/blog/post/${slug}`">
      <p class="text-desc" v-html="text"></p>
    </router-link>
  </article>
</template>
<script>
export default {
  name: "PostCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
    slug: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
article {
  position: relative;
  max-width: 360px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding-bottom: 20px;
  .img-holder {
    position: relative;
    width: 100%;
    padding-top: 65%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 0 auto;
  }

  .title-holder {
    position: relative;
    width: 90%;
    margin: -45px auto 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    .date-block {
      position: relative;
      width: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: stretch;
      align-items: center;
      background-color: var(--yellow1);
      p {
        font-size: 1.1em;
        color: #fff;
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
        &:first-child {
          font-size: 1.4em;
          font-weight: bolder;
        }
      }
    }
    .text {
      position: relative;
      width: calc(100% - 50px);
      padding: 12px;
      h1 {
        font-size: 1.4em;
        line-height: 1.5em;
      }
    }
  }
  p.text-desc {
    position: relative;
    display: block;
    width: 90%;
    margin: 15px auto 0 auto;
  }
}
</style>
