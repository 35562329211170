import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import moment from "moment";
import VueMoment from "vue-moment";
require("moment/locale/pt-br");
Vue.use(VueMoment, { moment });

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
const options = {
  confirmButtonColor: "#0f2651",
  cancelButtonColor: "#aaa",
};
Vue.use(VueSweetalert2, options);

Vue.config.productionTip = false;

import "./assets/css/desktop.css";
import "./assets/css/mobile.css";
import "./assets/css/tablet.css";
import "./assets/css/animate.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faChevronLeft,
  faChevronRight,
  faCopy,
  faEnvelope,
  faFilter,
  faSearch,
  faSortDown,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import {} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faChevronLeft,
  faChevronRight,
  faSearch,
  faFacebook,
  faInstagram,
  faLinkedin,
  faYoutube,
  faSortDown,
  faFilter,
  faWhatsapp,
  faEnvelope,
  faCopy,
  faBars,
  faTimes
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
