<template>
  <div id="app">
    <transition leave-active-class="animated fadeOut">
      <div v-show="!termsAccepted" class="terms-acceptance">
        <div class="container">
          <div>
            <h1>Uso de Cookies</h1>
            <p>
              Utilizamos cookies para oferecer uma melhor experiência, analisar
              o tráfego do site e veicular anúncios direcionados. Isso é feito
              para que possamos fazer melhorias em nosso site para futuros
              usuários. Acesse nossa
              <router-link to="/politica-de-privacidade"
                >Política de Privacidade</router-link
              >
              e
              <router-link to="/politica-de-cookies"
                >Política de Cookies</router-link
              >
              para que esteja de acordo e ciente.

              <!-- Utilizamos cookies para oferecer uma melhor experiência, analisar o
            tráfego do site e veicular anúncios direcionados. <br />
            Ao continuar, você concorda com o uso de cookies de acordo com nossa
            . -->
            </p>
          </div>

          <button class="btn-terms" @click="acceptTerms">Entendi</button>
        </div>
      </div>
    </transition>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      termsAccepted: false,
    };
  },
  created() {
    this.termsAccepted = localStorage.cookiesTermsAcceptance ? true : false;
  },
  methods: {
    acceptTerms() {
      localStorage.cookiesTermsAcceptance = "accepted";
      this.termsAccepted = true;
    },
  },
};
</script>
<style lang="scss">
:root {
  --primary: #ffcd00;
  --secondary: #4c8c2b;
  --bg-green: rgba(76, 140, 43, 0.85);
  --green1: #19a654;
  --yellow1: #ffd601;
  --yellow2: #ffb800;
  --red1: #e11010;
  --red2: #f51b42;
  --orange1: #fd9433;
  --dark1: #001623;
  --dark2: #1a1a1a;
  --dark3: #202020;
  --dark4: #323232;
  --breakpoint-mobile: 720px;
  --breakpoint-tablet: 1139px;
}

.color-primary {
  color: var(--primary) !important;
}

.color-secondary {
  color: var(--secondary) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}
.bg-secondary {
  background-color: var(--secondary) !important;
}

.desaturate {
  filter: saturate(0) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

.bg-red {
  background-color: var(--red1) !important;
}

*:not(path):not(table):not(thead):not(tbody):not(tr):not(th):not(td):not(td
    p):not(td p span):not(td p span strong) {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: fontRegular;
  text-decoration: none;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
label,
a,
svg,
div {
  font-size: 13px;
  color: var(--dark4);
}

.html-content {
  h1 {
    font-size: 1.8em !important;
    *:not(table):not(thead):not(tbody):not(tr):not(th):not(td) {
      font-size: 1em !important;
      color: inherit;
    }
  }
  h2 {
    font-size: 1.6em !important;
    *:not(table):not(thead):not(tbody):not(tr):not(th):not(td) {
      font-size: 1em !important;
      color: inherit;
    }
  }
  h3 {
    font-size: 1.4em !important;
    *:not(table):not(thead):not(tbody):not(tr):not(th):not(td) {
      font-size: 1em !important;
      color: inherit;
    }
  }
  h4 {
    font-size: 1.4em !important;
    *:not(table):not(thead):not(tbody):not(tr):not(th):not(td) {
      font-size: 1em !important;
      color: inherit;
    }
  }
  h5 {
    font-size: 1.4em !important;
    *:not(table):not(thead):not(tbody):not(tr):not(th):not(td) {
      font-size: 1em !important;
      color: inherit;
    }
  }
  h6 {
    font-size: 1.4em !important;
    *:not(table):not(thead):not(tbody):not(tr):not(th):not(td) {
      font-size: 1em !important;
      color: inherit !important;
    }
  }
  font {
    * {
      color: inherit !important;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 10px !important;
  }
  p,
  pre,
  span,
  div {
    font-family: fontRegular !important;
    font-size: 15px !important;
  }
  div.summary {
    padding-top: 0 !important;
  }
  font[size="5"] {
    position: relative;
    display: block;
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 10px !important;
    padding: 0 !important;
  }
  font[size="3"] {
    position: relative;
    display: block;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 15px !important;
  }
  font[size="2"] {
    position: relative;
    display: block;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 12px !important;
  }
  ul li {
    list-style: initial;
    list-style-position: inside;
    margin-top: 8px !important;
    p {
      font-family: fontRegular !important;
      display: initial !important;
    }
    span {
      font-family: fontRegular !important;
    }
  }
  table {
    position: relative;
    width: 100% !important;
    height: initial !important;
    border-collapse: collapse !important;
    font-family: inherit !important;
    tbody tr:first-child td {
      position: relative;
      width: initial !important;
      padding: 8px 0 !important;
    }
    tbody tr:first-child td p,
    tbody tr:first-child td p span,
    tbody tr:first-child td p span strong {
      margin: 0;
      font-size: 1.2em;
    }
    tbody tr:not(:first-child) td,
    tbody tr:not(:first-child) td span,
    tbody tr:not(:first-child) td p,
    tbody tr:not(:first-child) td p span,
    tbody tr:not(:first-child) td p span strong {
      margin: 0 !important;
      padding: 0 !important;
    }
    tbody tr:not(:first-child) td {
      padding: 10px !important;
    }
  }
}
@font-face {
  font-family: "fontRegular";
  src: url("/fonts/FiraSans-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "fontLight";
  src: url("/fonts/FiraSans-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "fontMedium";
  src: url("/fonts/FiraSans-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "fontBold";
  src: url("/fonts/FiraSans-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "fontBlack";
  src: url("/fonts/FiraSans-Black.ttf");
  font-display: swap;
}

html,
body {
  min-height: 100vh;
  min-width: 100vw;
}

.clearfix {
  clear: both !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-black {
  background-color: #000 !important;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.text-red {
  color: #f00 !important;
}

.text-blue {
  color: var(--blue) !important;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary) !important;
}

.text-desc {
  font-family: fontRegular;
  font-size: 1.1em;
  line-height: 1.5em;
}

.mr-left {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap !important;
}

ul {
  list-style: none;
}

section {
  position: relative;
  width: 100vw;
  padding: 75px 0 !important;
  &.intro {
    margin-top: 66px;
  }
}

.terms-acceptance {
  position: fixed;
  width: 100vw;
  padding: 35px 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fff;
  z-index: 99999999;

  h1 {
    color: #000;
    font-size: 1.2em;
    font-family: fontBold;
    margin-bottom: 0.6em;
  }

  p {
    color: #000;
    font-size: 1.15em;
    line-height: 1.5;
  }

  a {
    color: var(--green1);
    font-size: 1em;
    cursor: pointer;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media only screen and (max-width: 720px) {
      flex-direction: column;
    }
  }

  .btn-terms {
    position: relative;
    display: flex;
    min-width: 120px;
    background: transparent;
    padding: 12px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
    color: var(--green1);
    border: 1px solid var(--green1);
    margin-left: 10px;
    @media only screen and (max-width: 720px) {
      margin: 20px auto 0 auto;
    }
  }
}

.posts-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.html-content {
  position: relative;
  max-width: 100%;
  p img {
    max-width: 100%;
    image-rendering: -webkit-optimize-contrast;
  }
}

.post-description-intern {
  * {
    font-size: 14px;
    line-height: 22px;
    max-width: 100%;
  }
  iframe {
    position: relative;
    width: 100%;
    max-width: 425px;
    height: 240px;
  }
  p img {
    position: relative;
    max-width: 49%;
    margin-top: 1.5rem;
    float: left;
    &:nth-child(even) {
      margin-left: 2%;
    }
    &:last-child {
      clear: both;
      float: none;
    }
  }
  h1 strong {
    font-size: 1.6em;
  }
  h2 strong {
    font-size: 1.4em;
  }
  h3 strong {
    font-size: 1.2em;
  }
}

.before-title {
  font-family: fontRegular;
  font-size: 1.1em;
  color: var(--yellow1);
  letter-spacing: 5px;
  margin-bottom: 15px;
}

.banner-title * {
  font-size: 1em;
  color: #fff;
}

.bg-cover {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 999;
}

.page-title {
  position: relative;
  width: 100vw;
  height: 76px;
  margin-top: 97px;
  overflow: hidden;
  img.bg {
    position: absolute;
    width: 95%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .container {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 720px) {
  .page-title {
    position: relative;
    width: 100vw;
    height: 76px;
    margin-top: 97px;
    img.bg {
      position: absolute;
      width: 115vw;
      height: 100%;
      left: 0;
      top: 0;
      transform: none;
    }
    .container {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.title {
  position: relative;
  font-family: fontBold;
  font-size: 2.6em;
  font-weight: lighter;
}

select {
  background: transparent;
}

.input-item {
  position: relative;
  width: 100%;
  margin: 15px 0 0 0;

  span {
    position: relative;
    font-family: fontLight;
    position: relative;
    display: block;
    font-size: 1.1em;
    margin-bottom: 5px;
    color: var(--secondary);

    .blue {
      color: var(--blue);
    }
  }

  input,
  select {
    font-family: fontRegular;
    font-size: 1.2em;
    position: relative;
    width: 100%;
    height: 36px;
    border: 1px solid #cfcfcf;
    background-color: transparent;
    padding-left: 8px;
  }
  textarea {
    position: relative;
    width: 100%;
    min-height: 100px;
    padding: 8px;
    border: 1px solid #cfcfcf;
    resize: none;
  }
}

.flex-between .input-item {
  width: 48%;
}

.input-item input::placeholder {
  font-family: fontLight;
}

.warning {
  font-family: fontLight;
  position: relative;
  text-align: center;
  font-size: 1.5em;
  margin-top: 36px;
  color: rgb(255, 208, 80);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.warning.red {
  color: #f00;
  text-shadow: none;
}

.warning.success {
  color: rgb(21, 165, 45);
  text-shadow: none;
}

.pointer {
  cursor: pointer;
}

.bottom-controls {
  position: relative;
  width: 100%;
  margin-top: 45px;
  align-items: center;

  img {
    position: absolute;
    width: 44%;
    max-width: 400px;
    left: -5vw;
  }

  button {
    margin: 0;
    &.last {
      margin: 0 0 0 auto;
      svg {
        margin-left: 18px;
      }
    }
  }
}

.swiper-pagination {
  &.banner-home-pagination {
    bottom: -20px !important;
  }
  bottom: -30px;
  .swiper-pagination-bullet {
    width: 15px;
    height: 2px;
    border-radius: 0;
    background-color: var(--secondary);
  }
  &.vorax .swiper-pagination-bullet {
    background-color: #fff;
  }
}

.full-screen-pagination {
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #fff;
    border: 2px solid #fff;
  }
  &.vorax .swiper-pagination-bullet {
    background-color: #fff;
    border-color: #333;
  }
}

.overflow-initial {
  overflow: initial !important;
}

.loading1 {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loading1 div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: var(--primary);
  animation: loading1 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loading1 div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.loading1 div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.loading1 div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes loading1 {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.btn {
  position: relative;
  padding: 17px 38px;
  border-radius: 2px;
  background-color: var(--primary);
  margin: 35px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  transition: background-color 0.25s ease;
  cursor: pointer;

  span {
    font-family: fontMedium;
    font-size: 1.2em;
    color: #fff;
    text-align: center;
    transition: color 0.25s ease;
  }

  svg {
    font-size: 1.3em;
    color: var(--secondary);
    transition: color 0.25s ease;
  }

  &.border {
    padding: 18px 38px;
    border: 2px solid var(--secondary);
    background-color: transparent;
    box-shadow: none;
    &:hover {
      background-color: var(--secondary);
      span,
      svg {
        color: #fff !important;
      }
    }
    span {
      color: var(--secondary);
    }

    &.yellow {
      border-color: var(--yellow1);
      span,
      svg {
        color: var(--yellow1);
      }
      &:hover {
        background-color: var(--yellow1);
      }
    }
  }

  &.icon span {
    margin-right: 18px;
  }
}

button.line-anim {
  position: relative;
  display: block;
  margin: 55px auto 0 auto;
  padding: 15px 42px;
  border-radius: 3px;
  background-color: var(--primary);
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
}

button.line-anim::before,
button.line-anim::after {
  position: absolute;
  content: "";
  width: 0;
  height: 2px;
  top: -5px;
  left: -5px;
  background-color: var(--primary);
  transition: width 0.3s ease;
}

button.line-anim::after {
  top: initial;
  left: initial;
  bottom: -5px;
  right: -5px;
}

button.line-anim:not(.calc):hover {
  background-color: #000;
  color: #fff;
}

button.line-anim:hover::before,
button.line-anim:hover::after {
  width: 60px;
}

.back-button {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}

.back-button svg {
  font-size: 1.3em;
}

.screen-loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #000000cf;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading2 {
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  animation-name: rotate-forever;
  animation-timing-function: linear;
  height: 20px;
  width: 20px;
  border: 2px solid var(--primary);
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
}

.loading2.black {
  border: 2px solid #000;
  border-right-color: transparent;
}

.loading2.white {
  border: 2px solid #fff;
  border-right-color: transparent;
}

.loading2.yellow {
  border: 2px solid var(--yellow1);
  border-right-color: transparent;
}

.full-loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.page-anim1 {
  animation-name: anim1;
  animation-duration: 0.3s;
}

.page-anim2 {
  animation-name: anim2;
  animation-duration: 0.3s;
}

.page-anim3 {
  animation-name: anim3;
  animation-duration: 0.3s;
}

@keyframes anim1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes anim2 {
  from {
    opacity: 0;
    transform: translateX(17vw);
  }
  to {
    opacity: 1;
    transform: translateX(0vw);
  }
}

@keyframes anim3 {
  from {
    opacity: 0;
    transform: translateY(25vw);
  }
  to {
    opacity: 1;
    transform: translateY(0vw);
  }
}

@media screen and (max-width: 720px) {
  .text-desc {
    font-size: 1.3em;
  }
}
</style>
