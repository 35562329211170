<template>
  <div class="search-card">
    <h1>PESQUISE POR PRODUTOS</h1>
    <div class="divider"></div>
    <div class="search-item">
      <input
        v-model="query"
        @keypress.enter="search"
        type="text"
        placeholder="Busca"
      />
      <font-awesome-icon @click="search" :icon="['fas', 'search']" />
    </div>
  </div>
</template>
<script>
export default {
  name: "SearchCard",
  data() {
    return {
      query: "",
    };
  },
  methods: {
    search() {
      if (this.query) {
        this.$router.push(`/produtos?keyword=${this.query}`);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.search-card {
  position: fixed;
  width: 90%;
  max-width: 480px;
  padding: 30px 20px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
  top: 44%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  z-index: 999;
  h1 {
    font-size: 1.5em;
    text-align: center;
    letter-spacing: 2px;
  }
  .divider {
    position: relative;
    margin: 35px auto;
    width: 100%;
    height: 1px;
    background-color: #ccc;
  }
  .search-item {
    position: relative;
    width: 100%;
    border-bottom: 2px solid var(--secondary);
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
      position: relative;
      width: 92%;
      height: 46px;
      font-size: 1.2em;
      padding-left: 5px;
    }
    svg {
      font-size: 1.2em;
      margin-right: 5px;
      color: var(--secondary);
      cursor: pointer;
    }
  }
}
</style>
