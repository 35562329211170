<template>
  <header>
    <div class="container">
      <router-link to="/home">
        <img
          class="logo"
          src="../assets/images/Tradecorp-logo.png"
          alt="Tradecorp logo"
        />
      </router-link>

      <nav v-show="showMenu">
        <ul>
          <li>
            <router-link to="/empresa">EMPRESA</router-link>
          </li>
          <li
            @mouseenter="showSubmenu = true"
            @mouseleave="showSubmenu = false"
            @click="showSubmenu = !showSubmenu"
          >
            <div class="flex-start">
              <span>PRODUTOS</span>
              <font-awesome-icon :icon="['fas', 'sort-down']" />
            </div>
            <transition
              enter-active-class="animated fadeIn"
              leave-active-class="animated zoomOut"
            >
              <ul v-show="showSubmenu" class="submenu">
                <li v-for="cat in categories" :key="cat.id">
                  <router-link :to="`/produtos?categoryId=${cat.id}`">{{
                    cat.name
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/produtos">+ VER TODOS</router-link>
                </li>
              </ul>
            </transition>
          </li>
          <li>
            <router-link to="/blog">BLOG</router-link>
          </li>
          <li>
            <router-link to="/nosso-time">NOSSO TIME</router-link>
          </li>
          <li>
            <router-link to="/contato">CONTATO</router-link>
          </li>
        </ul>
      </nav>

      <font-awesome-icon
        class="search-icon"
        :icon="['fas', 'search']"
        @click="showSearch = true"
      />

      <font-awesome-icon
        class="search-icon desktop-hidden table-hidden"
        :icon="['fas', 'bars']"
        @click="showMenu = !showMenu"
      />
    </div>

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-show="showSearch"
        class="bg-cover"
        @click="showSearch = false"
      ></div>
    </transition>

    <transition
      enter-active-class="animated zoomIn"
      leave-active-class="animated zoomOut"
    >
      <SearchCard v-show="showSearch" />
    </transition>
  </header>
</template>
<script>
import http from "@/http";
import SearchCard from "@/components/SearchCard";
export default {
  name: "Header",
  components: {
    SearchCard,
  },
  data() {
    return {
      showSubmenu: false,
      showSearch: false,
      showMenu: window.innerWidth > 720,
      categories: [],
    };
  },
  created() {
    http
      .get(`category/List?orderBy=name-`)
      .then((res) => {
        this.categories = res.data.list;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  width: 100vw;
  height: 66px;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;

  .container {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img.logo {
      max-width: 200px;
    }
  }

  nav {
    margin-left: auto;
    > ul {
      position: relative;
      min-width: 410px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0 25px;

      li div {
        cursor: pointer;
        span {
          position: relative;
          margin-right: 8px;
        }
        svg {
          position: relative;
          font-size: 13px;
          top: -1px;
        }
      }

      a,
      span,
      svg {
        position: relative;
        font-size: 13px;
        transition: color 0.25s ease;
        &:hover {
          color: var(--primary);
        }
      }

      div:hover > svg,
      div:hover > span {
        color: var(--primary);
      }

      .submenu {
        position: absolute;
        top: 16px;
        background-color: #fff;
        padding: 28px 12px 0 12px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
        li a {
          position: relative;
          display: block;
          height: 40px;
        }
      }
    }
  }

  .search-icon {
    position: relative;
    margin-left: 22px;
    font-size: 1.2em;
    cursor: pointer;
  }
}

.router-link-exact-active {
  color: var(--primary);
}

@media only screen and (max-width: 720px) {
  header {
    .container {
      position: relative;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img.logo {
        max-width: 170px;
      }
    }

    nav {
      margin-left: auto;
      > ul {
        position: fixed;
        left: 0;
        top: 0;
        margin: 66px auto 0 auto;
        display: block;
        a,
        li > div {
          position: relative;
          display: block;
          height: 45px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          background-color: #fff;
          padding-left: 5%;
        }
        li div {
          cursor: pointer;
          span {
            position: relative;
            margin-right: 8px;
          }
          svg {
            position: relative;
            top: -3px;
          }
        }

        a,
        span,
        svg {
          position: relative;
          transition: color 0.25s ease;
          &:hover {
            color: var(--primary);
          }
        }

        div:hover > svg,
        div:hover > span {
          color: var(--primary);
        }

        .submenu {
          position: relative;
          top: 0;
          background-color: #fff;
          padding: 28px 12px 0 12px;
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
          li a {
            position: relative;
            display: block;
            height: 40px;
          }
        }
      }
    }

    .search-icon {
      position: relative;
      margin-left: 22px;
      font-size: 1.2em;
      cursor: pointer;
    }
  }
}
</style>
