import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    alias: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/empresa",
    name: "Empresa",
    component: () => import("../views/Empresa.vue"),
  },
  {
    path: "/contato",
    name: "Contato",
    component: () => import("../views/Contato.vue"),
  },
  {
    path: "/nosso-time",
    name: "NossoTime",
    component: () => import("../views/NossoTime.vue"),
  },
  {
    path: "/produtos",
    name: "Produtos",
    component: () => import("../views/Produtos.vue"),
  },
  {
    path: "/produtos/:id",
    name: "ProdutoInterna",
    component: () => import("../views/ProdutoInterna.vue"),
  },
  {
    path: "/blog",
    name: "Blog",
    component: () => import("../views/Blog.vue"),
  },
  {
    path: "/blog/post/:slug",
    name: "BlogPost",
    component: () => import("../views/BlogPost.vue"),
  },
  {
    path: "/voraxbiofertilizante",
    name: "Vorax",
    component: () => import("../views/Vorax.vue"),
  },
  {
    path: "/politica-de-privacidade",
    name: "Terms",
    component: () => import("@/views/TermosDeUso.vue"),
  },
  {
    path: "/politica-de-cookies",
    name: "TermsCookies",
    component: () => import("@/views/TermosCookies.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    if (from.path != to.path) {
      return { x: 0, y: 0 };
    }
  },
});

router.afterEach((to) => {
  gtag("config", window.GA_TRACKING_ID, {
    page_path: to.fullPath,
    send_page_view: true,
  });
});

export default router;
