<template>
  <footer>
    <div class="container">
      <div class="block">
        <img
          class="logo"
          src="../assets/images/Tradecorp-logo-transparent.png"
          alt="Tradecorp logo"
        />
      </div>
      <div class="block">
        <div class="col-title">NAVEGUE</div>
        <router-link to="/home">Home</router-link>
        <router-link to="/empresa">Empresa</router-link>
        <router-link to="/produtos">Produtos</router-link>
        <router-link to="/blog">Blog</router-link>
        <router-link to="/nosso-time">Nosso Time</router-link>
        <router-link to="/contato">Contato</router-link>
      </div>

      <div class="block">
        <div class="col-title">CONTATO</div>
        <a href="mailto:atendimento@tradecorp.rovensa.com"
          >atendimento@tradecorp.rovensa.com</a
        >
        <div class="flex-start">
          <a
            href="https://www.facebook.com/tradecorpdbrasil/"
            target="_blank"
            class="mr-1"
          >
            <font-awesome-icon :icon="['fab', 'facebook']" />
          </a>
          <a
            href="https://www.instagram.com/tradecorpdobrasil/"
            target="_blank"
            class="mr-1"
          >
            <font-awesome-icon :icon="['fab', 'instagram']" />
          </a>
          <a
            href="https://www.linkedin.com/company/tradecorp-do-brasil"
            target="_blank"
            class="mr-1"
          >
            <font-awesome-icon :icon="['fab', 'linkedin']" />
          </a>
          <a href="https://www.youtube.com/tradecorpdobrasil" target="_blank">
            <font-awesome-icon :icon="['fab', 'youtube']" />
          </a>
        </div>
        <div class="mt-1 visibility-on-contact">
          <div class="col-title">DOCUMENTAÇÃO LEGAL</div>
          <router-link to="/politica-de-privacidade"
            >Política de Privacidade</router-link
          >
          <router-link to="/politica-de-cookies"
            >Política de Cookies</router-link
          >
        </div>
      </div>
      <div class="block visibility-out-contact">
        <div class="col-title">DOCUMENTAÇÃO LEGAL</div>
        <router-link to="/politica-de-privacidade"
          >Política de Privacidade</router-link
        >
        <router-link to="/politica-de-cookies">Política de Cookies</router-link>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
};
</script>
<style lang="scss" scoped>
footer {
  position: relative;
  width: 100vw;
  padding: 65px 0 120px 0;
  background-color: #f0f0f0;
  .container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 55px;
    .block a {
      display: block;
      font-size: 1.15em;
      margin-bottom: 12px;
      &:hover {
        text-decoration: underline;
      }
    }
    .col-title {
      position: relative;
      color: var(--secondary);
      font-family: fontBold;
      font-size: 1.15em;
      height: 32px;
      margin-bottom: 15px;
      border-bottom: 2px solid var(--secondary);
    }

    svg {
      font-size: 1.32em;
      transition: color 0.25s ease;
      &:hover {
        color: var(--yellow1);
      }
    }
  }
}

.logo {
  max-width: 280px;
}

.image-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
}

.visibility-on-contact {
  display: none;
}

@media only screen and (min-width: 1160px) and (max-width: 1458px) {
  .visibility-on-contact {
    display: block;
  }

  .visibility-out-contact {
    display: none;
  }
}

@media screen and (max-width: 1159px) {
  .container {
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)) !important;
  }
  .block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .col-title {
      width: 100%;
      text-align: center;
    }
  }
}
</style>
