import axios from "axios";

const endpoint =
  process.env.NODE_ENV === "development"
    ? "https://localhost:44306/api/"
    : "https://api.tradecorp.com.br/api/";

const http = axios.create({
  baseURL: endpoint,
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error.response.status);
    return Promise.reject(error);
  }
);

export default http;
